// 视图组件
const view = {
  tabs: () => import('@/layouts/tabs'),
  blank: () => import('@/layouts/BlankView'),
  page: () => import('@/layouts/PageView')
}

// 路由组件注册
const routerMap = {
  login: {
    authority: '*',
    path: '/login',
    component: () => import('@/pages/login')
  },
  root: {
    path: '/',
    name: '登录页',
    redirect: '/login',
    component: view.tabs
  },
  dashboard: {
    name: '工作台',
    icon: 'dashboard',
    component: view.blank
  },
  workplace: {
    name: '首页',
    component: () => import('@/pages/dashboard/workplace/WorkPlace')
  },
  sfa_sales_programs: {
    name: '销售方案',
    icon: 'file-search',
    component: view.blank
  },
  sfa_demandList: {
    name: '需求单',
    icon: 'file-search',
    component: view.blank
  },
  sfa_demand_list: {
    name: '慰问品',
    component: () => import('@/pages/sfa_demandList/list/index'),
  },
  sfa_demand_add: {
    name: '需求单',
    component: () => import('@/pages/sfa_demandList/components/form'),
  },
  sfa_demand_details: {
    name: '需求单详情',
    component: () => import('@/pages/sfa_demandList/details/details'),
  },
 
  sfa_demand_scheme: {
    name: '方案设计',
    component: () => import('@/pages/sfa_demandList/scheme/scheme'),
  },
  sfa_workOrder: {
    name: '销售单',
    icon: 'menu-unfold',
    component: view.blank
  },
  product_solution: {
    name: '产品方案',
    component: () => import('@/pages/programme/QueryList'),
  },
  OutboundList: {
    name: '卡券销售单',
    component: () => import('@/pages/sfa_workOrder/OutboundList'),
  },
  InvoiceApplyList: {
    name: '发票申请',
    component: () => import('@/pages/sfa_workOrder/InvoiceApplyList'),
  },
  offlineActiveList: {
    name: '线下活动',
    component: () => import('@/pages/sfa_workOrder/offlineActiveList'),
  },
  sfa_applyActivity: {
    name: '线上活动',
    component: () => import('@/pages/sfa_workOrder/sfa_applyActivity'),
  },
  addInvoiceApply: {
    name: '发票申请单',
    component: () => import('@/pages/sfa_workOrder/addInvoiceApply'),
  },
  editInvoiceApply:{
    name: '发票申请单详情',
    component: () => import('@/pages/sfa_workOrder/editInvoiceApply'),
  },
  addWorkOrder :{
    name: '新建',
    component: () => import('@/pages/sfa_workOrder/addWorkOrder'),
  },
  detailsWorkOrder:{
    name: '详情',
    component: () => import('@/pages/sfa_workOrder/detailsWorkOrder'),
  },
  editWorkOrder:{
    name: '编辑',
    component: () => import('@/pages/sfa_workOrder/editWorkOrder'),
  },
  query_form: {
    name: '商品列表',
    component: () => import('@/pages/selection/QueryList'),
  },

  sfa_commodity_management: {
    name: '商品管理',
    icon: 'table',
    component: view.blank
  },
  product_library:{
    name: '商品库',
    component: () => import('@/pages/selection/productLibrary')
  },

  sfa_mine: {
    name: '个人中心',
    icon: 'user',
    component: view.blank
  },

  sfa_goOut: {
    name: '外出管理',
    icon:'logout',
    component: view.blank
  },
  sfa_goOut_visit: {
    path: 'sfa_goOut_visit',
    name: '拜访记录',
    component: () => import('@/pages/sfa_goOut/sfa_goOut_visit')
  },
  sfa_telephoneRecord: {
    name: '通话数据',
    icon: 'phone',
    component: view.blank
  },
  sfa_history_record: {
    path: 'sfa_history_record',
    name: '通话记录',
    component: () => import('@/pages/sfa_telephoneRecord/sfa_history_record')
  },
  recordList: {
    path: 'recordList',
    name: '通话数据',
    component: () => import('@/pages/sfa_telephoneRecord/recordList')
  },
  workloadList: {
    path: 'workloadList',
    name: '通话工作量',
    component: () => import('@/pages/sfa_telephoneRecord/workloadList')
  },
 
  sfa_financial: {
    name: '销售数据',
    icon: 'money-collect',
    component: view.blank
  },
  statistics: {
    path: 'statistics',
    name: '销售看板',
    component: () => import('@/pages/sfa_financial/statistics')
  },
  sfa_cargo_pile:{
    path: 'sfa_cargo_pile',
    name: '货堆明细',
    component: () => import('@/pages/sfa_financial/sfa_cargo_pile')
  },
  sfa_residential_allocation:{
    path: 'sfa_residential_allocation',
    name: '宅配明细',
    component: () => import('@/pages/sfa_financial/sfa_residential_allocation')
  },
  sfa_card_history:{
    path: 'sfa_card_history',
    name: '卡券明细',
    component: () => import('@/pages/sfa_financial/sfa_card_history')
  },
  sfa_activity_list:{
    path: 'sfa_activity_list',
    name: '线下活动明细',
    component: () => import('@/pages/sfa_financial/sfa_activity_list')
  },
  sfa_activity_details:{
    path: 'sfa_activity_details',
    name: '线下活动明细详情',
    component: () => import('@/pages/sfa_financial/sfa_activity_details')
  },
  exportTaskList:{
    name: '导出任务列表',
    component: () => import('@/pages/programme/ExportTask')
  },

  sfa_resource: {
    name: '资源管理',
    icon: 'pic-right',
    component: view.blank
  },
  sfa_brands_offline:{
    name: '线下品牌',
    component: () => import('@/pages/sfa_resource/sfa_brands_offline'),
  },
  sfa_goods_list:{
    name: '商品池列表',
    component: () => import('@/pages/sfa_resource/sfa_goods_list'),
  },
  sfa_cake_online:{
    name: '线上蛋糕品牌',
    component: () => import('@/pages/sfa_resource/sfa_cake_online'),
  },
  sfa_storeList:{
    name: '门店列表',
    component: () => import('@/pages/sfa_resource/sfa_storeList'),
  },
  sfa_cinemaList:{
    name: '全国影院',
    component: () => import('@/pages/sfa_resource/sfa_cinemaList'),
  },
  sfa_contract:{
    name: '销售合同',
    component: () => import('@/pages/sfa_resource/sfa_contract'),
  },
  sfa_invite:{
    name: '投标资料',
    component: () => import('@/pages/sfa_resource/sfa_invite'),
  },
  sfa_customer: {
    name: '客户管理',
    icon: 'team',
    component: view.blank
  },
  sfa_customer_list:{
    name: '客户列表',
    component: () => import('@/pages/sfa_customer/sfa_customer_list'),
  },
  sfa_customer_statistics:{
    name: '客户统计',
    component: () => import('@/pages/sfa_customer/sfa_customer_statistics'),
  },
  sfa_customerMineList:{
    name: '我的客户',
    component: () => import('@/pages/sfa_customer/sfa_customerMineList'),
  },
  customer_list_All:{
    name: '公海客户',
    component: () => import('@/pages/customerAll/list'),
  },
  sfa_uncommonCustomers:{
    name: '长期未跟进客户',
    component: () => import('@/pages/sfa_customer/sfa_uncommonCustomers'),
  },
  sfa_customer_details:{
    name: '客户列表详情',
    component: () => import('@/pages/sfa_customer/sfa_customer_details'),
  },
  addForm:{
    name: '编辑',
    component: () => import('@/pages/sfa_customer/addForm/AdvancedForm'),
  },
  InvoiceList:{
    name: '发票列表',
    component: () => import('@/pages/sfa_customer/InvoiceList'),
  },
  Contact:{
    name: '联系人',
    component: () => import('@/pages/sfa_customer/Contact'),
  },
  ContactAddress:{
    name: '联系人收货地址',
    component: () => import('@/pages/sfa_customer/ContactAddress'),
  },


 
}
export default routerMap

